/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Voucher, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1p5u9qo css-idll4v --style2 --full mt--40 pt--02" anim={""} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/41322/6e49197e0022409cad9aa9e50619ada3_s=3000x_.png);
    }
  
background-position: 43% 12%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"}>
              
              <Title className="title-box fs--154 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: var(--color-supplementary);\"><br><br><br>Dárkové poukazy<br>Májule makeup</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Voucher className="pb--40 pt--40" name={"bdgsh9fvv9"}>
        </Voucher>


        <Column className="--center pb--60 pt--60" name={"nd1zzbxzbv"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":1230}} content={"Poukaz na email dorazí během chvilky od zaplacení. Systém čeká na připsání částky na účet, proto doporučuji zvolit okamžitou platbu a nebo poslat potvrzení na email info@majulemakeup.cz<br><br>&nbsp;Pro urychlení a ověření objednávky pošlete sms ve tvaru KUPON_JMÉNO OBJEDNAVATELE na číslo 727 812 079 v čase <span style=\"font-weight: bold;\">8:30-19:30</span>. <br>V noci mě prosím nechte spinkat :)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">+420 727 812 079<br>info@majulemakeup.cz</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Marie Navrátilová IČ: 07178026<br>Palackého 413 Hronov<br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}